import React, { Component, Fragment } from "react"
import { graphql } from "gatsby"

import Context from "../components/Context"

export default class NotFound extends Component {
  componentDidMount() {
    const { data } = this.props
    // current page
    const blog = data.blog

    const header = {
      currentName: blog.title,
      currentType: "blog",
    }
    if (this.context.data.header !== header) {
      this.context.set({ header })
    }

    const { mapdata } = blog
    if (this.context.data.mapdata !== mapdata) {
      this.context.set({ mapdata: { bbox: mapdata.bbox } })
    }
  }

  render() {
    return (
      <Fragment>
        <div className="uk-background-secondary uk-light">
          <div className="uk-section uk-position-relative">
            <div className="uk-container uk-container-medium uk-animation-fade">
              <h3 className="uk-text-uppercase uk-margin-medium-bottom">
                ADMIN
              </h3>
            </div>
          </div>
        </div>
      </Fragment>
    )
  }
}

NotFound.contextType = Context

export const query = graphql`
  query {
    blog: contentfulBlog {
      contentful_id
      name
      title
      mapdata
      events {
        contentful_id
        name
        title
        slug
        parts {
          contentful_id
          name
          title
          slug
          days {
            contentful_id
            name
            title
            slug
          }
        }
      }
    }
  }
`
